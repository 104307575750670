import { push } from "react-router-redux";
import QueryString from "query-string";

import { store } from "../../../store";
import { confirmAlert } from "react-confirm-alert";
import userTriggerActions from "../../../actions/userTriggerActions";
import notificationActions from "../../general/notification/NotificationsManager/actions";
import cacheActions from "../../../actions/cacheActions";

const userTriggerTableColumns = [
  {
    name: 'clientName',
    title: 'Nome do Cliente',
    meta: { valueKey: "id", labelKey: "name" },
    width: 250,
  },
  {
    name: 'clientAdvisor',
    title: 'Responsável',
    editable: false,
    width: 150,
  },
  {
    name: 'clientCpf',
    title: 'CPF do Cliente',
    editable: false,
    width: 150,
  },
  {
    name: "netTotal",
    title: "NET Total",
    editable: false,
    type: "brCurrency",
    precision: 2,
    width: 150,
    filterInitialValue: { minValue: 0.01 }
  },
  {
    name: "btgCode",
    title: "Conta BTG",
    editable: false,
    width: 100,
  },
  {
    name: "xpCode",
    title: "Conta XP",
    editable: false,
    width: 100,
  },
  {
    name: 'clientEmail',
    title: 'E-mail do Cliente',
    editable: false,
    width: 250,
  },
  {
    name: 'active',
    title: 'Envios Habilitados',
    editable: true,
    width: 180,
    type: "checkbox",
  },
  {
    name: 'emailEnabled',
    title: 'via E-mail',
    editable: true,
    width: 133,
    type: "checkbox",
  },
  {
    name: 'whatsappEnabled',
    title: 'via WPP',
    editable: true,
    width: 133,
    type: "checkbox",
  }
];

const userTriggerTableLeftFixedColumns = ["operations", "clientName"];

const handleUpdateAll = (params) => {
  store
    .dispatch(userTriggerActions.updateAll(params))
    .then((apiResponse) => {
      const pathId = apiResponse.data.triggerTypeId;
      store.dispatch(notificationActions.showNotification("Atualizado com sucesso", "success"));
      // store.dispatch(cacheActions.cleanCache()).then(() => {
      //   store.dispatch(userTriggerActions.fetchAll(pathId));
      // }).catch((err) => {
      //   console.log(err);
      // });
      store.dispatch(cacheActions.cleanCache());
      store.dispatch(userTriggerActions.fetchAll());
      
    })
    .catch((apiResponse) => {
      const message = apiResponse.data.errors[0].detail;
      const pathId = apiResponse.data.triggerTypeId;
      store.dispatch(notificationActions.showNotification(message, "danger"));
      store.dispatch(cacheActions.cleanCache());
      store.dispatch(userTriggerActions.fetchAll());
    });
};

const handleSendAll = (params) => {
  store
    .dispatch(userTriggerActions.sendAll(params))
    .then((apiResponse) => {
      const pathId = apiResponse.data.triggerTypeId;
      store.dispatch(notificationActions.showNotification("Solicitação de envio processada com sucesso! Caso o cliente se enquadre nas condições de envio, a notificação será imediatamente enviada.", "success"));
      store.dispatch(cacheActions.cleanCache());
      store.dispatch(userTriggerActions.fetchAll());
    })
    .catch((apiResponse) => {
      const message = apiResponse.data.errors[0].detail;
      const pathId = apiResponse.data.triggerTypeId;
      store.dispatch(notificationActions.showNotification(message, "danger"));
      store.dispatch(cacheActions.cleanCache());
      store.dispatch(userTriggerActions.fetchAll());
    });
};

const showUpdateAllConfirmationAlert = (message, params) =>
  confirmAlert({
    title: "Confirmação",
    message,
    buttons: [
      {
        label: "Sim",
        onClick: () => handleUpdateAll(params),
      },
      {
        label: "Não",
        onClick: () => {},
      },
    ],
  });

  const showSendAllConfirmationAlert = (message, params) =>
    confirmAlert({
      title: "Confirmação",
      message,
      buttons: [
        {
          label: "Sim",
          onClick: () => handleSendAll(params),
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });

const userTriggerSelectionOperations = [
  {
    label: "Habilitar Envios Automáticos",
    field: "active",
    value: true,
    confirmationMessage: "Habilitar os envios automáticos de Informações de Carteira para os clientes selecionados?",
  },
  {
    label: "Desabilitar Envios Automáticos",
    field: "active",
    value: false,
    confirmationMessage: "Desabilitar os envios automáticos de Informações de Carteira para os clientes selecionados?",
  },
  {
    label: "Habilitar Envios por E-mail",
    field: "email_enabled",
    value: true,
    confirmationMessage: "Desabilitar os envios automáticos por e-mail para os clientes selecionados?",
  },
  {
    label: "Desabilitar Envios por E-mail",
    field: "email_enabled",
    value: false,
    confirmationMessage: "Desabilitar os envios automáticos por e-mail para os clientes selecionados?",
  },
  {
    label: "Habilitar Envios por Whatsapp",
    field: "whatsapp_enabled",
    value: true,
    confirmationMessage: "Desabilitar os envios automáticos por whatsapp para os clientes selecionados?",
  },
  {
    label: "Desabilitar Envios por Whatsapp",
    field: "whatsapp_enabled",
    value: false,
    confirmationMessage: "Desabilitar os envios automáticos por whatsapp para os clientes selecionados?",
  }
].map((operation) => ({
  label: operation.label,
  action: (selectedUserTriggers) => {
    const params = {
      user_trigger_ids: selectedUserTriggers
        .filter((item) => item != null)
        .map((item) => item.id),
      field: operation.field,
      value: operation.value
    };

    showUpdateAllConfirmationAlert(operation.confirmationMessage, params);
  },
  renderCondition: (selectedClients) => selectedClients.length > 0,
}));

const userTriggerTableSummaries = [
  { columnName: "netTotal", type: "brCurrencySum" }
];

export { userTriggerTableSummaries, userTriggerTableLeftFixedColumns, userTriggerSelectionOperations, handleUpdateAll, showUpdateAllConfirmationAlert, showSendAllConfirmationAlert };
export default userTriggerTableColumns;
